/** @jsxImportSource theme-ui */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Container from '@groot/components/Container';
import { Button, Flex } from 'theme-ui';
import { StatsItem } from './components';
import { theme } from '@groot/shared/util';
import groCoinImage from './images/groCoinImage.png';
import groArrowImage from './images/groArrowImage.png';
import bridgeImage from './images/bridgeImage.png';
import {
  useGetGroPrice,
  useGetHminePrice,
  useGetPerpsPrice,
  useGetGroData,
} from '@groot/hooks/useGetGroPrice';

import {
  Hero,
  HeroContent,
  HeroObject,
  StatsSection,
  AssetDocumentationSection,
  AssetDocumentationContent,
  StyledAction,
  BoostMorSection,
} from './gro.styles';

function numberWithCommas(x) {
  return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // .split('.')[0];
}

const Gro = () => {
  const groPrice = useGetGroPrice();
  const hminePrice = useGetHminePrice();
  const perpsPrice = useGetPerpsPrice();
  const { groBurnt, groTotalSupply, groCirculation } = useGetGroData();

  return (
    <>
      <Hero>
        <Container>
          <Flex
            sx={{ alignItems: 'center', justifyContent: 'space-between', flexFlow: 'row wrap' }}
          >
            <HeroContent>
              <h1 sx={{ ...theme.styles.h1, mb: 20 }}>GRO Token</h1>
              <p>
                GRO is the core token of the ecosystem and receives revenue from all Growth DeFi
                products, including xPERPS Protocol.
              </p>
              <br />
              <p>
                GRO is a low-supply token that has multiple buyback and burn mechanisms in place
                that make it deflationary.
              </p>
              <br />
              <p>
                <b>GRO Token:</b> 0x09e64c2b61a5f1690ee6fbed9baf5d6990f8dfd0 <br />
                <b>GrowthBTC:</b> 0x00febf86e8f0673f0feadac14b5ea1a05e744cb7 <br />
                <b>4WMM:</b> 0x8bf45680485b2ac15e452a9599e87b94c5a07792 <br />
                <b>Email:</b> admin@growthdefi.com
              </p>
            </HeroContent>
            <HeroObject>
              <img src={groCoinImage} alt="" style={{ width: '80%' }} />
            </HeroObject>
          </Flex>
        </Container>
      </Hero>
      <Container>
        <StatsSection>
          <StatsItem title="Price" value={`$${numberWithCommas(groPrice)}`} />
          <StatsItem title="Total Burned" value={numberWithCommas(groBurnt)} />
          <StatsItem title="Circulating supply" value={numberWithCommas(groCirculation)} />
          <StatsItem title="Total supply" value={numberWithCommas(groTotalSupply)} />
          <StatsItem title="Marketcap" value={`$${numberWithCommas(groTotalSupply * groPrice)}`} />
        </StatsSection>

        {/* <StatsSection>
          <StyledAction>
            <h2>GRO</h2>
            <div
              style={{
                border: '1px solid #06EBC8',
                padding: '10px',
                borderRadius: '25px',
                width: '100%',
                marginBottom: '15px',
              }}
            >{`$${groPrice.toFixed(2)}`}</div>
            <p>GRO offers a few types of staking and farming protocols.</p>

            <a
              href="https://pancakeswap.finance/swap?inputCurrency=0x4AdAE3Ad22c4e8fb56D4Ae5C7Eb3abC0dd2d3379&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button
                sx={{
                  width: '100%',
                  color: 'white',
                  background: 'linear-gradient(180deg, #16C5E7 0%, #11A9C7 100%)',
                }}
              >
                Buy GRO
              </Button>
            </a>
            <a
              href="https://perps.growthdefi.com/xgro/staking"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button
                sx={{
                  width: '100%',
                  color: 'white',
                  background: '#06EBC8',
                }}
              >
                Stake GRO
              </Button>
            </a>
          </StyledAction>
          <StyledAction>
            <h2>xxHMINE</h2>
            <div
              style={{
                border: '1px solid #06EBC8',
                padding: '10px',
                borderRadius: '25px',
                width: '100%',
                marginBottom: '15px',
              }}
            >{`$${hminePrice.toFixed(2)}`}</div>
            <p>
              The mission of Hypermine is to offer a dynamic, exciting and community-driven
              ecosystem.
            </p>
            <a
              href="https://pancakeswap.finance/swap?inputCurrency=0xde886E293a5BF5ebd7d6FDf7504Fb368A0D9aF44&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button
                sx={{
                  width: '100%',
                  color: 'white',
                  background: 'linear-gradient(180deg, #16C5E7 0%, #11A9C7 100%)',
                }}
              >
                Buy xxHMINE
              </Button>
            </a>
            <a href="https://hypermine.club" target="_blank" rel="noreferrer noopener">
              <Button
                sx={{
                  width: '100%',
                  color: 'white',
                  background: '#06EBC8',
                }}
              >
                Visit Hypermine
              </Button>
            </a>
          </StyledAction>
          <StyledAction>
            <h2>xPERPS</h2>
            <div
              style={{
                border: '1px solid #06EBC8',
                padding: '10px',
                borderRadius: '25px',
                width: '100%',
                marginBottom: '15px',
              }}
            >{`$${perpsPrice.toFixed(4)}`}</div>
            <p>A token that boosts your staking positions to earn more rewards.</p>
            <a
              href="https://pancakeswap.finance/swap?inputCurrency=0x849Cc5ef9DDd08691EE908aD8001003b4bF104e5&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button
                sx={{
                  width: '100%',
                  color: 'white',
                  background: 'linear-gradient(180deg, #16C5E7 0%, #11A9C7 100%)',
                }}
              >
                Buy xPERPS
              </Button>
            </a>
            <a
              href="https://perps.growthdefi.com/xperps/yield-farm"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button
                sx={{
                  width: '100%',
                  color: 'white',
                  background: '#06EBC8',
                }}
              >
                xPERPS Farm
              </Button>
            </a>
          </StyledAction>
        </StatsSection> */}
      </Container>
      {/* <BoostMorSection>
        <h2 sx={{ ...theme.styles.h2 }}>Boost your xGRO staking rewards with xPERPS</h2>
        <p>Lock your xPERPS to earn more xGRO on the xGRO VDC.</p>

        <a href="https://perps.growthdefi.com" target="_blank" rel="noreferrer noopener">
          <Button
            sx={{
              border: '1px solid #FFFFFF',
              boxSizing: 'border-box',
              borderRadius: '5px',
              background: 'transparent',
            }}
          >
            LAUNCH xPERPS
          </Button>
        </a>
      </BoostMorSection> */}

      <AssetDocumentationSection as="section">
        <Container>
          <AssetDocumentationContent>
            <h3 sx={{ ...theme.styles.h3, mb: 40 }}>
              Explore the Documentation to Learn How GRO Works Within the Growth DeFi Ecosystem
            </h3>
            <a href="/doc/Litepaper.pdf" target="_blank" rel="noreferrer">
              <Button variant="primary" sx={{ height: 50, pr: 30, pl: 30, fontSize: 16 }}>
                Asset Documentation
              </Button>
            </a>
          </AssetDocumentationContent>
        </Container>
      </AssetDocumentationSection>
    </>
  );
};

export default Gro;
