const Tokens = {
  GRO: {
    56: '0x4adae3ad22c4e8fb56d4ae5c7eb3abc0dd2d3379',
    250: '0x91F1430833879272643658f8eD07d60257dDf321',
    369: '0x09e64c2b61a5f1690ee6fbed9baf5d6990f8dfd0',
    43114: '0x72699ba15CC734F8db874fa9652c8DE12093F187',
  },
  xPERPS: {
    56: '0x849Cc5ef9DDd08691EE908aD8001003b4bF104e5',
    43114: '0x72699ba15CC734F8db874fa9652c8DE12093F187',
  },
  xxHMINE: {
    56: '0xde886E293a5BF5ebd7d6FDf7504Fb368A0D9aF44',
    43114: '0x72699ba15CC734F8db874fa9652c8DE12093F187',
  },
  WHEAT: {
    56: '0x3ab63309F85df5D4c3351ff8EACb87980E05Da4E',
    43114: '0x1A51686Fb42861AA7E38c1CF8868877F43F82aA4',
  },
  LQDR: {
    250: '0x10b620b2dbAC4Faa7D7FFD71Da486f5D44cd86f9',
  },
  cLQDR: {
    250: '0x814c66594a22404e101FEcfECac1012D8d75C156',
  },
};

export default Tokens;
