/** @jsxImportSource theme-ui */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import NavMenu from './NavMenu';
import { theme } from '@groot/shared/util';
import throttle from 'lodash/throttle';
import { useGetGroPrice } from '@groot/hooks/useGetGroPrice';
import GRO_Token from 'assets/img/gro.svg';
import useEagerConnect from '@groot/hooks/useEagerConnect';
const StyledHeader = styled.header`
  // position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 15px;
  background: ${(props) => (props.pageScrolled ? '#141414' : 'none')};
  transition: 0.3s;

  ${theme.mediaQuery.min.sm} {
    height: 100px;
    padding: 25px 30px;
  }
`;

const GROTicker = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: rgb(33, 33, 33);
  border-radius: 25px;
  border: 1px solid rgb(43, 43, 43);
  padding: 5px 15px;
  margin-left: 25px;
  height: 35px;
  margin-top: 10px;
  min-width: 110px;
  cursor: pointer;
  transition: all 0.3s ease 0s;

  &:hover {
    border: solid 1px rgb(254, 200, 18);
  }
`;

const BrandContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export default function NewHeader() {
  useEagerConnect();
  const [pageScrolled, setPageScrolled] = React.useState(false);
  const price = useGetGroPrice();
  useEffect(() => {
    const throttledScroll = throttle(() => {
      if (window.pageYOffset >= 100) {
        setPageScrolled(true);
      } else {
        setPageScrolled(false);
      }
    }, 100);

    window.addEventListener('scroll', throttledScroll);

    return () => window.removeEventListener('scroll', throttledScroll);
  }, []);

  return (
    <StyledHeader pageScrolled={pageScrolled}>
      <BrandContainer>
        <Logo />
        <GROTicker>
          <img src={GRO_Token} height={24} /> <p>${price.toFixed(2)}</p>
        </GROTicker>
      </BrandContainer>

      <NavMenu />
    </StyledHeader>
  );
}
