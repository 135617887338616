/* eslint-disable react/prop-types */
/** @jsxImportSource theme-ui */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'theme-ui';
import BurgerButton from './BurgerButton';
import ConnectBtn from './ConnectBtn';
import { theme } from '@groot/shared/util';
import { ReactComponent as CloseIcon } from './images/CloseIcon.svg';
import Doc from 'assets/doc/Litepaper.pdf';

const SideMenuContainer = styled.aside`
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  height: 100%;
  padding: 20px 15px;
  background: rgb(28, 28, 28);
  box-shadow: rgb(0 0 0 / 53%) -10px 0px 30px;
  transform: ${({ isOpen }) => (isOpen ? 'translate(0, 0)' : 'translate(110%, 0)')};
  transition: all 0.3s ease;

  ${theme.mediaQuery.min.md} {
    max-width: 450px;
    padding: 30px;
  }
`;

const CloseButton = styled.button`
  align-self: flex-end;
  margin: 0 0 40px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  ${theme.mediaQuery.min.md} {
    margin: 0 0 80px;
  }
`;

const Menu = styled.ul`
  margin-bottom: 24px;

  ${theme.mediaQuery.min.md} {
    margin-bottom: 48px;
  }

  li + li {
    margin-top: 24px;

    ${theme.mediaQuery.min.md} {
      margin-top: 48px;
    }
  }

  a {
    line-height: 1;
    color: #fff;

    ${theme.mediaQuery.min.md} {
      font-size: 22px;
    }
  }
`;

const MobileMenu = styled(Menu)`
  ${theme.mediaQuery.min.md} {
    display: none;
  }
`;

// eslint-disable-next-line react/prop-types
const ExternalLink = ({ children, href, onClick }) => (
  <a href={href} target="_blank" onClick={onClick} rel="noopener noreferrer">
    {children}
  </a>
);

ExternalLink.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
};

const SideMenu = (props) => {
  const [isSideMenuOpen, setSideMenuOpen] = React.useState(false);

  const handleCloseMenu = () => {
    setSideMenuOpen(false);
  };

  return (
    <>
      <BurgerButton
        onClick={() => {
          setSideMenuOpen(true);
        }}
      />
      <SideMenuContainer isOpen={isSideMenuOpen}>
        <CloseButton
          onClick={() => {
            setSideMenuOpen(false);
          }}
        >
          <CloseIcon />
        </CloseButton>
        <MobileMenu>
          <li
            sx={{
              '@media (min-width: 1200px)': {
                display: 'none',
              },
            }}
          >
            {props.isBridgePath ? (
              <ConnectBtn sx={{ width: '100%', height: 50 }} />
            ) : (
              <a
                sx={{ padding: '0 !important' }}
                href="https://growthdefi.gitbook.io/docs/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Button sx={{ width: '100%', textTransform: 'uppercase' }}>
                  Asset Documentation
                </Button>
              </a>
            )}
          </li>
          <li>
            <Link to="/" onClick={handleCloseMenu}>
              Home
            </Link>
          </li>

          <li>
            <a href="https://app.growthdefi.com">app</a>
          </li>

          <li>
            <a href="https://hypermine.club/">Hypermine</a>
          </li>

          <li>
            <a href="https://perps.growthdefi.com/">xPERPS</a>
          </li>

          <li>
            <a href="https://perps.growthdefi.com/xgro/staking">Staking</a>
          </li>
        </MobileMenu>
        <Menu>
          <li>
            <Link to="/gro" onClick={handleCloseMenu}>
              GRO
            </Link>
          </li>
          {/* <li>
            <ExternalLink href="https://hypermine.club" onClick={handleCloseMenu}>
              Hypermine
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://xyield.growthdefi.com" onClick={handleCloseMenu}>
              xYield
            </ExternalLink>
          </li> */}

          <li>
            {/* <ExternalLink href="https://growthdefi.gitbook.io/docs/" onClick={handleCloseMenu}>
              Docs
            </ExternalLink> */}
            <Link to={Doc} target="_blank" onClick={handleCloseMenu}>
              Litepaper
            </Link>
          </li>
          <li>
            <ExternalLink
              href="https://consensys.net/diligence/audits/2021/06/growthdefi-wheat/"
              onClick={handleCloseMenu}
            >
              Audit
            </ExternalLink>
          </li>
          <li>
            <ExternalLink
              href="https://scan.mypinata.cloud/ipfs/bafybeidn64pd2u525lmoipjl4nh3ooa2imd7huionjsdepdsphl5slfowy/#/token/0x09e64c2B61a5f1690Ee6fbeD9baf5D6990F8dFd0"
              onClick={handleCloseMenu}
            >
              Token Contract
            </ExternalLink>
          </li>
        </Menu>
      </SideMenuContainer>
    </>
  );
};

export default SideMenu;
