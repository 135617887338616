/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import getBSCNodeUrl, { getAVAXNodeUrl, getFTMNodeUrl } from './getBSCNodeUrl';
import { BscConnector } from '@binance-chain/bsc-connector';
import { ethers } from 'ethers';
import { NetworkConnector } from '@web3-react/network-connector';

const WalletContext = React.createContext({
  showWalletModal: false,
  setShowWalletModal: () => null,
});

const WalletContextProvider = ({ children }) => {
  const [showWalletModal, setShowWalletModal] = useState(false);

  return (
    <WalletContext.Provider value={{ showWalletModal, setShowWalletModal }}>
      {children}
    </WalletContext.Provider>
  );
};

export const connectorLocalStorageKey = 'connectorIdForGrowthDefiV2';
export const ConnectorNames = {
  MetaMask: 'MetaMask',
  WalletConnect: 'WalletConnect',
};

export const injected = new InjectedConnector({ supportedChainIds: [56, 43114, 250] });
const rpcUrl = getBSCNodeUrl();
const avaxUrl = getAVAXNodeUrl();
const ftmURL = getFTMNodeUrl();
const walletconnect = new WalletConnectConnector({
  rpc: { 56: rpcUrl, 43114: avaxUrl, 250: ftmURL },
  supportedChainIds: [56, 43114],
});

export const network = new NetworkConnector({
  urls: { 56: rpcUrl, 43114: avaxUrl, 250: ftmURL },
  defaultChainId: 56,
});

export const connectorsByName = {
  [ConnectorNames.MetaMask]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
};

export const bscConnector = new BscConnector({ supportedChainIds: [56, 43114, 250] });

export const signMessage = async (provider, account, message) => {
  if (window.BinanceChain) {
    const { signature } = await window.BinanceChain.bnbSign(account, message);
    return signature;
  }

  if (provider.provider?.wc) {
    const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message));
    const signature = await provider.provider?.wc.signPersonalMessage([wcMessage, account]);
    return signature;
  }

  return provider.getSigner(account).signMessage(message);
};

export { WalletContext, WalletContextProvider };
