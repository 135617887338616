/** @jsxImportSource theme-ui */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Container from '@groot/components/Container';
import { Button, Flex } from 'theme-ui';
import { StatsItem } from './components';
import { theme } from '@groot/shared/util';
import groCoinImage from './images/groCoinImage.png';
import groArrowImage from './images/groArrowImage.png';
import bridgeImage from './images/bridgeImage.png';
import {
  useGetGroPrice,
  useGetHminePrice,
  useGetPerpsPrice,
  useGetGroData,
} from '@groot/hooks/useGetGroPrice';

import {
  Hero,
  HeroContent,
  HeroObject,
  StatsSection,
  AssetDocumentationSection,
  AssetDocumentationContent,
  StyledAction,
  BoostMorSection,
} from './docs.styles';

function numberWithCommas(x) {
  return x
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    .split('.')[0];
}

const Gro = () => {
  const groPrice = useGetGroPrice();
  const hminePrice = useGetHminePrice();
  const perpsPrice = useGetPerpsPrice();
  const { groBurnt, groTotalSupply, groCirculation } = useGetGroData();

  return (
    <>
      <Hero>
        <Container>
          <Flex
            sx={{ alignItems: 'center', justifyContent: 'space-between', flexFlow: 'row wrap' }}
          >
            <HeroContent>
              <h1 sx={{ ...theme.styles.h1, mb: 20 }}>Litepaper</h1>
              <h1 sx={{ ...theme.styles.h1, mb: 20 }}>Tokenomics</h1>
              <p>
                4WMM - 4 way reflections token, reflects pGRO, pWBTC, pDAI, PLS Fees 6% buy 9% sell,
                all taxes go towards holder reflections. 869 Total supply. Hold in wallet to earn
                reflections, to collect transfer 0 4WMM to yourself and they will be sent to your
                wallet.
              </p>
              <br />
              <p>
                GBTC - Growth Bitcoin, reflects pWBTC, is deflationary. Fees 3.5% buy tax, 3.5% sell
                tax, half goes to reflections, half to burning of GBTC. Hold in wallet to earn
                reflections, to collect transfer 0 GBTC to yourself, and they will be sent to your
                wallet.
              </p>
              <br />
              <p>
                pGRO - GROWTH is our flagship token, which much revenue will be directed towards.
                Low supply only about 160K coins, it is the copy token from when we launched on ETH
                in 2020.
              </p>
              <br />
              <h1 sx={{ ...theme.styles.h1, mb: 20 }}>Short Term Roadmap</h1>
              <p>
                4WMM Listing next on CG, then we start CMC for all 3 tokens, there should be an
                explainer vid coming soon for all tokens and one devoted to just 4WMM in the coming
                days... Then we do a few cex listings, and then move into leveraging platform launch
                phase, and marketing push... All going to plan...
              </p>
              <br />
              <h1 sx={{ ...theme.styles.h1, mb: 20 }}>Staking</h1>
              <p>
                You can currently stake GBTC in the drip pool on Growthdefi.com, and once the
                leveraging platform has launched you will be able to stake GBTC or 4WMM there to
                earn a portion of the liquidations. GRO staking will be coming soon as well.
              </p>
              <br />
              <h1 sx={{ ...theme.styles.h1, mb: 20 }}>Contract addresses</h1>
              <p>
                <b>GRO Token PLS</b>: 0x09e64c2b61a5f1690ee6fbed9baf5d6990f8dfd0
                <br />
                <b>GrowthBTC PLS</b>: 0x00febf86e8f0673f0feadac14b5ea1a05e744cb7
                <br />
                <b>4WMM PLS</b>: 0x8bf45680485b2ac15e452a9599e87b94c5a07792
              </p>
            </HeroContent>
            <HeroObject>
              <img src={groCoinImage} alt="" style={{ width: '80%' }} />
            </HeroObject>
          </Flex>
        </Container>
      </Hero>
    </>
  );
};

export default Gro;
