/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';
import { jsx } from 'theme-ui';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useMediaQuery } from 'react-responsive';
import { theme } from '@groot/shared/util';
import {
  CounterContainer,
  CounterItems,
  CounterItem,
  CounterItemText,
  CounterItemValue,
  WheatLogo,
  FantomLogo,
  MorLogo,
} from './counter.styled';

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

import wheatLogo from './images/WheatLogo.svg';
import fantomLogo from './images/FantomLogo.svg';
import morLogo from './images/MorLogo.svg';
const renderTime = (dimension, time) => {
  return <CounterItemValue>{time}</CounterItemValue>;
};

const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

// eslint-disable-next-line react/prop-types
const Counter = (props) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const timerProps = {
    isPlaying: true,
    size: isMobile ? 72 : 120,
    strokeWidth: 6,
  };

  const startTime = Date.now() / 1000; // use UNIX timestamp in seconds
  const endTime = 1685545200; // 1638122400; // 1636761600; // use UNIX timestamp in seconds
  const isCounting = endTime > startTime;

  const remainingTime = endTime - startTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  return (
    <CounterContainer
      data-aos="fade-up"
      data-aos-offset="-440"
      data-aos-delay="200"
      data-aos-duration="600"
      data-aos-easing="ease-in-out"
      data-aos-mirror="false"
      data-aos-once="true"
      data-aos-anchor-placement="top-center"
      {...props}
    >
      <h2
        sx={{
          ...theme.styles.h2,
          mb: 20,
        }}
      >
        Soft launching in
      </h2>
      <CounterItems>
        <CounterItem>
          <CountdownCircleTimer
            {...timerProps}
            colors={[
              ['#D35C8E', 0],
              ['#6E41E8', 1],
            ]}
            isLinearGradient={true}
            duration={daysDuration}
            initialRemainingTime={isCounting ? remainingTime : 0}
            trailColor={[['#9c9c9c']]}
          >
            {({ elapsedTime }) => renderTime('days', getTimeDays(daysDuration - elapsedTime))}
          </CountdownCircleTimer>
          <CounterItemText>Days</CounterItemText>
        </CounterItem>
        <CounterItem>
          <CountdownCircleTimer
            {...timerProps}
            colors={[
              ['#D35C8E', 0],
              ['#6E41E8', 1],
            ]}
            isLinearGradient={true}
            duration={daySeconds}
            initialRemainingTime={isCounting ? remainingTime % daySeconds : 0}
            trailColor={[['#9c9c9c']]}
            onComplete={(totalElapsedTime) => [remainingTime - totalElapsedTime > hourSeconds]}
          >
            {({ elapsedTime }) => renderTime('hours', getTimeHours(daySeconds - elapsedTime))}
          </CountdownCircleTimer>
          <CounterItemText>Hours</CounterItemText>
        </CounterItem>
        <CounterItem>
          <CountdownCircleTimer
            {...timerProps}
            colors={[
              ['#D35C8E', 0],
              ['#6E41E8', 1],
            ]}
            isLinearGradient={true}
            duration={hourSeconds}
            initialRemainingTime={isCounting ? remainingTime % hourSeconds : 0}
            trailColor={[['#9c9c9c']]}
            onComplete={(totalElapsedTime) => [remainingTime - totalElapsedTime > minuteSeconds]}
          >
            {({ elapsedTime }) => renderTime('minutes', getTimeMinutes(hourSeconds - elapsedTime))}
          </CountdownCircleTimer>
          <CounterItemText>Minutes</CounterItemText>
        </CounterItem>
      </CounterItems>

      <h2
        sx={{
          ...theme.styles.h2,
          mb: 20,
        }}
      >
        Full launch TBA
      </h2>
    </CounterContainer>
  );
};

export default Counter;
