import random from 'lodash/random';

// Array of available nodes to connect to
export const nodes = [
  'https://bsc-dataseed1.ninicoin.io',
  'https://bsc-dataseed1.defibit.io',
  'https://bsc-dataseed.binance.org',
];

export const avax_nodes = ['https://api.avax.network/ext/bc/C/rpc'];

export const ftm_nodes = ['https://rpc.ftm.tools/'];

export const pulse_nodes = ['https://pulsechain-rpc.publicnode.com'];

const getBSCNodeUrl = () => {
  const randomIndex = random(0, nodes.length - 1);
  return nodes[randomIndex];
};

export const getAVAXNodeUrl = () => {
  const randomIndex = random(0, avax_nodes.length - 1);
  return avax_nodes[randomIndex];
};

export const getFTMNodeUrl = () => {
  const randomIndex = random(0, avax_nodes.length - 1);
  return ftm_nodes[randomIndex];
};

export const getPULSENodeUrl = () => {
  const randomIndex = random(0, pulse_nodes.length - 1);
  return pulse_nodes[randomIndex];
};

export const getNodeUrl = (chain) => {
  if (chain === 'avax' || chain === 43114) {
    return getAVAXNodeUrl();
  }
  if (chain === 'ftm' || chain === 250) {
    return getFTMNodeUrl();
  }
  if (chain === 'bsc' || chain === 56) {
    return getBSCNodeUrl();
  } else {
    return getPULSENodeUrl();
  }
};

export default getPULSENodeUrl;
